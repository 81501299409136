var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-info"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/contact-logo.png"),"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"btn flex-c",on:{"click":_vm.showCallDialog}},[_c('span',{staticClass:"flex-c"},[_c('font-awesome-icon',{attrs:{"icon":"phone"}}),_vm._v("\n          "+_vm._s(_vm.info.phone)+"\n        ")],1)]),(_vm.isMobile)?_c('div',{staticClass:"btn flex-c"},[_c('span',{staticClass:"flex-c",on:{"click":_vm.showMessengerDialog}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook-messenger']}}),_vm._v("FB 諮詢\n        ")],1)]):_c('a',{staticClass:"btn flex-c",attrs:{"href":_vm.info.fbMessage,"target":"_blank"},on:{"click":function($event){_vm.window.dotq = _vm.window.dotq || [];
 _vm.window.dotq.push(
 {
   'projectId': '10000',
   'properties': {
     'pixelId': '10113125',
     'qstrings': {
       'et': 'custom',
       'ea': 'FB10113125'
     }
 } } );}}},[_c('span',{staticClass:"flex-c"},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook-messenger']}}),_vm._v("FB 諮詢\n        ")],1)]),_c('a',{staticClass:"btn flex-c",attrs:{"href":_vm.info.fbLink,"target":"_blank"}},[_c('span',{staticClass:"flex-c"},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook-f']}}),_vm._v("前往粉絲專頁\n        ")],1)]),_c('div',{staticClass:"address flex-c"},[_vm._v(_vm._s(_vm.info.address))]),(_vm.isMobile)?_c('div',{staticClass:"btn flex-c"},[_c('span',{staticClass:"flex-c",on:{"click":_vm.showMapDialog}},[_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt"}}),_vm._v("導航 Google 地圖\n        ")],1)]):_c('a',{staticClass:"google-btn flex-c",attrs:{"href":_vm.info.googleLink,"target":"_blank"},on:{"click":function($event){_vm.window.dotq = _vm.window.dotq || [];
 _vm.window.dotq.push(
 {
   'projectId': '10000',
   'properties': {
     'pixelId': '10113125',
     'qstrings': {
       'et': 'custom',
       'ea': 'map10113125'
     }
 } } );}}},[_c('span',{staticClass:"flex-c"},[_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt"}}),_vm._v("導航 Google 地圖\n        ")],1)]),_c('el-dialog',{attrs:{"title":"","visible":_vm.isShowCallDialog,"width":("" + (_vm.isMobile ? '90%' : '500px')),"modal-append-to-body":false},on:{"update:visible":function($event){_vm.isShowCallDialog=$event}}},[_c('CallDialog',{attrs:{"phone":_vm.info.phone}})],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.isShowMessengerDialog,"width":"90%","modal-append-to-body":false},on:{"update:visible":function($event){_vm.isShowMessengerDialog=$event}}},[_c('MessengerDialog',{attrs:{"messenger":_vm.info.fbMessage}})],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.isShowMapDialog,"width":"90%","modal-append-to-body":false},on:{"update:visible":function($event){_vm.isShowMapDialog=$event}}},[_c('MapDialog',{attrs:{"link":_vm.info.googleLink,"address":_vm.info.address}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }