var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog-content"},[_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt"}}),_c('div',{staticClass:"dialog-desc"},[_vm._v("接待會館")]),_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.address))]),_c('a',{staticClass:"cta",attrs:{"href":_vm.link,"target":"_blank"},on:{"click":function($event){_vm.window.dotq = _vm.window.dotq || [];
_vm.window.dotq.push(
{
  'projectId': '10000',
  'properties': {
    'pixelId': '10113125',
    'qstrings': {
      'et': 'custom',
      'ea': 'map10113125'
    }
} } );}}},[_vm._v("開啟導航")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }