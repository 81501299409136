export default [
  { name: '首頁', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '' },
  { name: '交響導聆', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '' },
  { name: '世紀繁華', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '' },
  { name: '奢華公設', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '' },
  { name: '究好的構築', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '' },
  { name: '精工團隊', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '' },
  { name: '歷年築跡', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '' },
  { name: '愛樂生活', imgSrc: '', subTitle: '', section: 'section10', svgSrc: '' },
  { name: '媒體報導', imgSrc: '', subTitle: '', section: 'section11', svgSrc: '' },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '' },
]












