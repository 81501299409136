export default {
  address: '241新北市三重區中正北路大仁街口',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.1546910795655!2d121.48948311500689!3d25.0627455839589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a904a97af995%3A0xe8c0cc3a227a6258!2z56-J56a-5Lqk6Z-_6Zmi!5e0!3m2!1szh-TW!2stw!4v1568116832178!5m2!1szh-TW!2stw',
  phone: '02-2972-8888',
  fbLink:
    'https://www.facebook.com/%E7%AF%89%E7%A6%BE%E4%BA%A4%E9%9F%BF%E9%99%A2-694156361100384/?ref=bookmarks',
  fbMessage: 'https://m.me/694156361100384',
  googleLink: 'https://goo.gl/maps/k8f7FmHFPEcXkKih6',
  caseName: '築禾交響院',

  houseInfos: [
    ['投資興建', '築禾建設股份有限公司'],
    ['廣告代銷', '新誠家廣告有限公司'],
    ['建築設計', '吳成榮建築師事務所'],
    ['公設設計', '磐石設計有限公司'],
    ['景觀設計', '老圃造園工程股份有限公司'],
    ['基地面積', '3,361坪'],
    ['樓層規劃', '27F/B5'],
    ['坪數規劃', '66、73坪']
  ],
  
  gtmCode: ['WBXSH54', '5MHDFPN', '5RH5WTR', '5N96QXB'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: 'Contact Info'
  }
}
