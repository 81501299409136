var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog-content"},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'facebook-messenger']}}),_c('div',{staticClass:"dialog-desc"},[_vm._v("Facebook Messenger")]),_c('div',{staticClass:"info"},[_vm._v("線上諮詢")]),_c('a',{staticClass:"cta",attrs:{"href":_vm.messenger,"target":"_blank"},on:{"click":function($event){_vm.window.dotq = _vm.window.dotq || [];
 _vm.window.dotq.push(
 {
   'projectId': '10000',
   'properties': {
     'pixelId': '10113125',
     'qstrings': {
       'et': 'custom',
       'ea': 'FB10113125'
     }
 } } );}}},[_vm._v("立即諮詢")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }